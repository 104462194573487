.sidebar {
  & :global(.MuiPaper-root) {
    background-color: #f6f6ff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
  }
}

.listicon {
  min-width: 35px !important;
}

.selectedItem {
  color: #ee0b6f;

  .listicon {
    color: #ee0b6f;
  }

  .listItemText {
    text-decoration: underline;
    color: #ee0b6f;
  }
}

.avatar {
  margin: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatarContainer {
  margin-bottom: 20px;
}

.logoutButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  margin-bottom: 10px;
}

.listItem {
  width: 100% !important;
}

.listItemLink {
  display: flex;
  align-items: center;
  width: auto;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.listItemText {
  margin-left: 10px;
}

.listItemLink:hover {
  .listicon {
    color: #ee0b6f;
  }

    .listItemText {
        color: #ee0b6f;
    }
}

  .logo {
    width: 45px;
    transition: opacity 0.3s ease;
  }


.link {
  align-items: center;
  display: flex;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
}

  .span {
    color: rgb(88, 28, 135);
    font-weight: 1000;
    margin-top: 5px;
  }

  .div {
    width: 100% !important;
    margin-left: 5px;
  }

  @media (max-height: 702px) {
    .div {
      margin-top: -65px;
    }
  }

