.card {
  display: flex;
  flex-direction: column;
  width: 200px; // Smaller width
  height: 226px; // Smaller height
  margin-bottom: 10px; // Adjusted margin
}

.mediaContainer {
  width: 100%;
  height: 125px; // Adjusted height
  overflow: hidden;
}

.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.content {
  text-align: center;
}

.title {
  font-family: 'Caveat', cursive !important;
  color: #cb2b63;
  font-size: 1.5rem !important; // Smaller font size
}

.capacity {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px; // Adjusted margin
}

.statusContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  font-size: 1rem; // Smaller font size
  margin-right: 5px; // Adjusted margin
}

.link {
  text-decoration: none;
  color: inherit;
}

.icon {
  margin-bottom: 2px; // Adjusted margin
}

.sensorIcon {
  font-size: 1.25rem !important;
}
