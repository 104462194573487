.home{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homeResponsive{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 2s ease-in-out;
  h1 {
    animation: moveFromAbove 1s ease-in-out;
  }
}

@media (max-width: 751px) {
  .home{
    margin-left:  10px;
  }
  .homeResponsive{
    margin-left:  10px;
  }
  .homeImg{
    width: 100%;
  }
}

.homeImg{
  animation: moveFromBelow 1s ease-in-out;
}

.mapHomeButton {
  flex: 1;
  margin-top: 150px;
  margin-right: 20px;
  background-color: #ff69b4;
  color: white;
  border: none;
  padding: 15px 100px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
  display: inline-block;
  animation: moveFromLeft 1s ease-in-out;
}

.mapHomeButton:hover {
  transform: scale(1.05);
}

.reservationHomeButton {
  flex: 1;
  margin-top: 150px;
  margin-left: 20px;
  background-color: #85daff;
  color: #ffffff;
  border: none;
  padding: 15px 100px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  display: inline-block;
  animation: moveFromRight 1s ease-in-out;
}

.reservationHomeButton:hover {
  transform: scale(1.05);
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .mapHomeButton, .reservationHomeButton {
    width: 110%;
    margin: 10px 0;
    padding: 15px 0;
  }

  .buttonContainer {
    flex-direction: column;
  }
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes moveFromAbove {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes moveFromBelow {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes moveFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}