@import 'react-big-calendar/lib/sass/styles';

.popupContainer{
  width: 300px;
  height: 400px;
}
.popupTextField{
  margin: 10px
}

.label {
  text-align: center;
}
.status{
  text-align: center;
  margin-bottom: 50px;
}
.popupTitle{
  font-family: 'Caveat', cursive;
  text-align: center;
}

.popupTime{
  text-align: center;
}

.container {
  margin-top: -80px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 4%;
  background-color: #fff !important;
}

.containerWithSidebar {
  margin-left: 50px;
  margin-top: -80px;
  margin-right: 4%;
}

.section {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline {
  position: relative;
  width: 80%;
  max-width: 500px;
  height: 1600px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}

.event {
  position: absolute;
  left: 10px;
  width: calc(100% - 20px);
  background-color: #ffcccb;
  color: #8b0000;
  border: 1px solid #8b0000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.eventTitle {
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.currentTimeIndicator {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: red;
}

.free {
  background-color: #98fb98;
  color: #006400;
}

.past {
  background-color: #d3d3d3;
  color: #696969;
}

@media (max-width: 526px) {
.containerWithSidebar{
  margin-left: 20px;
}
}





