.container {
  padding-bottom: 50px;
}

.roomName {
  font-family: 'Caveat', cursive;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 80px;
  margin-top: -130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #cb2b63;
}

.roomNameWithSidebar {
  font-family: 'Caveat', cursive;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 80px;
  margin-top: -130px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 46px;
  height: 100%;
  color: #cb2b63;
}

.timeButton {
  margin: 0 5px 3px !important;
  border-radius: 21px !important;
}

.dateField {
  flex: 1;
}

@media (min-width: 600px) {
  .dateField {
    flex: 530px;
  }
}

.fieldMargin {
  margin: 8px 2px !important;
}

@media (max-width: 526px) {
  .roomNameWithSidebar {
    margin-left: 20px;
  }
}

.dialog {
  padding: 20px;
}

.formBox {
  margin-top: 10px;
}

.title {
  margin-bottom: 15px;
}
