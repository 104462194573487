.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  margin-top: -90px;
}

.arrowContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 200px;
  top: 30%;
  gap: 10px;
  transform: translateY(-50%);
  z-index: 1000;
  @media (max-width: 1080px) {
    position: static;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    right: 0;
    top: 0;
    transform: none;
  }
}

.arrow {
  background-color: #ffffff !important;
  color: #000000;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover:not(:disabled) {
    background-color: #ffcc99;
    transform: scale(1.05);
  }

  &:disabled {
    cursor: default;
    background-color: #c92b62 !important;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 28px;
  }
}

.floorName {
  font-family: 'Caveat', cursive;
  font-size: 2.5rem;
  font-weight: bold;
  color: #cb2b63;
  margin-top: 0;
  text-align: center;
}

.mapWrapper {
  position: relative;
  width: 50%;
  border: 0 solid #c72b61;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 5px;
  background-color: #fff;
  @media (max-width: 500px){
    width: 80%;
    height: 500px;
  }
}

.mapContainer {
  width: 100%;
  height: 100%;
  margin: auto;
}

.mapImage {
  width: 1100px;
}

.roomDotContainer {
  position: absolute;
  display: flex;
  cursor: pointer;
  transition: transform 0.2s;
  border: 4px solid;
  @media (min-width: 768px) {
    gap: 4px;
  }

  &:hover {
    transform: scale(1.2);
    z-index: 9999;
  }
}

.roomDot {
  width: 16px !important;
  height: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    width: 20px;
    height: 20px;
  }
}

.tooltip {
  position: absolute;
  top: -200px;
  left: -75px;
  width: 220px;
  background-color: transparent;
  border: none;
  padding: 0;
  display: none;
  z-index: 10000 !important;

  @media (min-width: 768px) {
    width: 220px;
  }
}

.roomDotContainer:hover .tooltip {
  display: block;
}

.bulleDot {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 3px solid white;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;


  @media (min-width: 768px) {
    width: 20px;
    height: 20px;
  }
}

.bulleDot:hover {
  transform: scale(1.2);
  z-index: 9999;
}

.bulleTooltip {
  position: absolute;
  bottom: 22px;
  left: -68px;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  display: none;
  z-index: 10000 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }

  @media (min-width: 768px) {
    width: 150px;
  }
}

.bulleDot:hover .bulleTooltip {
  display: block;
}

.openSpaceMap {
  transform: scale(1);
  transform-origin: center;
}

.etageMap {
  transform: scale(1);
  transform-origin: center;
}

.title {
  font-family: 'Caveat', cursive !important;
  color: #cb2b63;
  font-size: 1.5rem !important; // Smaller font size
}
