.button {
  &:global(.MuiButton-root) {
    color: rgb(88, 28, 135);
    font-weight: 1000;
    font-size: 15px;
    min-width: 5px;
    text-decoration: none;
    top: 100%;
    left: 12%;
  }

  &:hover {
    color: #ee0b6f;
    background-color: #f6f6ff !important;
  }
}
