.container {
  display: flex;
  flex-direction: column;
  margin-left:  20px;
  margin-top: 10px;
}

.button {
  left: 92%;
}

.containerWithSidebar {
  margin-left: 40px;
  margin-top: -100px
}
