.container {
  min-width: 650px;
  margin-left: 10px;
  margin-top: -100px
}

.button {
  left: 92%;
  margin-left: 45px !important;
}

.containerWithSidebar {
  margin-left: 40px;
  margin-top: -100px
}