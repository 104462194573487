.button {
  height: 60px;
  width: 300px;
  position: static;
  margin: 20px 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem !important;
  text-transform: none;
  border-radius: 78px !important;
}

.button:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.buttonSearch {
  height: 60px;
  width: 300px;
  margin: 20px 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem !important;
  text-transform: none;
  border-radius: 78px !important;
}

.buttonSearch:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.mediaContainer {
  height: auto;
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.media {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  transition: transform 0.3s;
}

.media:hover {
  transform: scale(1.02);
}

.bottomMedia {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
  align-self: center;
  text-align: center;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-left: 20px;
}

.infos {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.capacity {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem !important;
  gap: 10px;
}

.icon {
  font-size: 3rem;
}

.roomName {
  font-family: 'Caveat', cursive;
  font-size: 3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #cb2b63;
  margin-bottom: 20px;
  margin-top: -100px;
}

.timeButton {
  margin: 0 5px 3px !important;
  border-radius: 21px !important;
}

.dateField {
  flex: 1;
}

@media (min-width: 600px) {
  .dateField {
    flex: 530px;
  }
}

.fieldMargin {
  margin: 8px 2px !important;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1px;
}

@media (max-width: 600px) {
  .button, .buttonSearch {
    width: 90%;
    margin: 10px 0;
    font-size: 1.2rem !important;
  }

  .buttonContainer {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .media {
    max-width: 100%;
    margin: 10px 0;
  }

  .bottomMedia {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .status {
    font-size: 1.4rem; /* Adjust font size for mobile */
    margin-left: 0;
    margin-top: 10px;
  }

  .capacity {
    font-size: 1.4rem !important;
  }

  .icon {
    font-size: 2.5rem;
  }

  .roomName {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  .timeButton {
    font-size: 1rem !important;
  }

  .container {
    padding: 10px;
  }
}

.formBox {
  margin-top: 10px;
}

.title {
  margin-bottom: 15px !important;
}
