.card {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-bottom: 20px;
  border-radius: 8px !important;
  background-color: #f7f7f7 !important;
}

@media (max-width: 420px) {
  .card {
    width: 100%;
  }

  .card .mediaContainer .media {
    width: 100%;
    height: auto;
  }
  .card .mediaContainer {
    height: auto;
  }
}

.mediaContainer {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.media {
  height: 100% !important;
  object-fit: cover;
  width: 100% !important;

}

.contentBox {
  display: flex;
  flex-direction: column;
}

.content {
}

.title {
  font-family: 'Caveat', cursive !important;
  color: #cb2b63;
  font-size: 3rem !important;
}

.capacity {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 1.25rem !important;
}

.status {
  font-size: 0.75rem !important;
  padding: 3px !important;
  position: relative;
  text-align: right;
  border-radius: 6px !important;
}

.link {
  text-decoration: none;
  color: inherit;
}

.icon {
  margin-bottom: 4px;
  font-size: 1.5rem !important;
}

.containerTitleCapacity{
  display: flex;
  justify-content: space-between;

}

.capacityContainer{
  display: flex;
  flex-direction: row-reverse;
  width: 60px;
  justify-content: space-evenly;
  align-items: center;
}

.statusContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center; // Ensure items are aligned
}

.sensorIcon {
  margin-left: 8px; // Add some space between the chip and the icon
  font-size: 1.25rem !important;
}
