.container {
  display: flex;
  flex-direction: column;
  margin-top: 10px
}

.button {
  left: 92%;
}

.containerWithSidebar {
  margin-left: 40px;
  margin-top: -100px
}
.row {
  cursor: pointer;

  &:hover {
    background-color: #c2e7ff;
  }
}
