.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 100vw;
  margin-top: -120px;
}

.tabContainer {
  margin-right: 10px;
  margin-left: 10px;
  width: 100%;
  margin-top: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.tabContent {
  display: flex;
  flex-direction: row;
}

.viewRows {
  width: 100%;
  height: 100%;
}

.arrowContainer {
  display: flex;
  flex-direction: column;
  position: absolute ;
  margin-right: 30px;
  margin-left: 30px;
  gap: 10px;
  z-index: 1000;
}

.selectedTab {
  background-color: #ffffff;
  color: #000000;
  border-radius: 10px 10px 0 0 !important;


  &:hover:not(:disabled) {
    transform: scale(1.05);
  }

  &:disabled {
    cursor: default;
    color: #cb2b63 !important;
  }
}

.arrow {
  background-color: #ffffff !important;
  color: #000000;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover:not(:disabled) {
    background-color: #c92b62 !important;
    transform: scale(1.1);
  }

  &:disabled {
    cursor: default;
    background-color: #e1e1e1 !important;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 28px;
  }
}

.floorName {
  font-family: 'Caveat', cursive;
  font-size: 2.5rem;
  font-weight: bold;
  color: #cb2b63;
  margin-top: 0;
  text-align: center;
  margin-bottom: 30px;
}

.mapWrapper {
  width: 3000px;
  height: 860px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  background-color: #fff;
  }

.mapContainer {
  position: relative;
  margin-right: 100px;
}

.mapImage {
  width: 1100px;
}
.headerSelect {
  margin: 1px;
  padding: 10px;


}

.roomDot {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 3px solid white;
  background-color: #007bff;
  cursor: pointer;
  transition: transform 0.2s;
  z-index: 10 !important;

  @media (min-width: 768px) {
    width: 20px;
    height: 20px;
  }
}

.roomDot:hover {
  transform: scale(1.2);
  z-index: 9999;
}

.tooltip {
  position: absolute;
  top: -30px;
  left: -50px;
  width: 100px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  display: none;
  z-index: 9999 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }

  @media (min-width: 768px) {
    width: 120px;
  }
}

.roomDot:hover .tooltip {
  display: block;
}

.bulleDot {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 3px solid white;
  border-radius: 50%;
  background-color: lightblue;
  cursor: pointer;
  transition: transform 0.2s;
  z-index: 10 !important;

  @media (min-width: 768px) {
    width: 16px;
    height: 16px;
  }
}

.bulleDot:hover {
  transform: scale(1.2);
  z-index: 9999;
}

.bulleTooltip {
  position: absolute;
  top: -30px;
  left: -50px;
  width: 100px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  display: none;
  z-index: 9999 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }

  @media (min-width: 768px) {
    width: 120px;
  }
}

.bulleDot:hover .bulleTooltip {
  display: block;
}

.controlPanel {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  position: absolute;
  top: 45%;
  right: 18%;
  transform: translate(50%, -50%);
}

.roomName {
  flex-grow: 1;
  text-align: center;
  font-family: 'Caveat', cursive !important;
  color: #c72b61;
  font-size: 2rem !important;

}

.activeDot {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    background-color: red;
  }
  50% {
    background-color: blue;
  }
  100% {
    background-color: red;
  }
}

.openSpaceMap {

}

.etageMap {

}

.sidebar {
  position: static;
  margin-top: 10px;
  width: 320px;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 30px;

}

.sidebar.open {
  transform: translateX(0);

  @media (max-width: 768px) {
    transform: translateY(0);
  }
}

.sidebarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.sidebarContent {
  padding: 10px;
}

.arrowAndMapContainer{
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}

.sideBarEdit {
  margin-left: 300px;
  width: 300px;
  height: 100%;
  border: 3px solid #c92b62;
  border-radius: 20px;
}

.sidebarButton {
  margin-top: 10px !important;
  width: 100%;
}

.sidebarAutocomplete {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.addBulleButton{
  width: 100%;
}