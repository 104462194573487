.container {
  display: flex;
  flex-direction: column;
  margin-top: -100px;
  margin-left: -5px;
}

.grid {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px 200px;
  grid-gap: 20px;
}

.containerWithSidebar {
  margin-left: 40px;
  margin-top: -100px
}

@media (max-width: 400px) {
  .containerWithSidebar{
    margin-left: 20px;
  }
}

.date {
  text-align: center;
}
.switchButton{
  margin-left: 30px;
}

.btn{
  width: 150px;
  margin-top: 34px !important;
  margin-left: 12px !important;
}

.btn_period{
  margin-top: -42px;
  margin-right: 30px ;
}

.room_stat{
  margin-top: 50px;
  margin-right: 130px;
}

.bulle_stat{
  margin-top: 50px;
  margin-right: 130px;
}

.bulleOverview {
  margin-left: 30px;
  margin-right: 15px;
}

.roomOverview{
    margin-left: 30px;
    margin-right: 15px;
}

.bottomContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 40px ;
}



